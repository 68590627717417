.leaflet-container {
  height: 700px;
  width: 100%;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}

#map {
  scroll-snap-align: center;
}
img {
  max-height: 100%
}
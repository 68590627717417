.swiper-button-prev {
  background: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.swiper-button-next {
  background: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

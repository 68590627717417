.button-color {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 0%,
    rgba(0, 154, 185, 1) 0%,
    rgba(0, 208, 213, 1) 63%,
    rgba(0, 154, 185, 1) 100%
  );
}

.custom-drop-shadow {
  filter: drop-shadow(0px 3px 7.5px rgba(48, 69, 100, 0.23));
}

.custom-border-radius-10 {
  border-radius: 0% 0% 10% 10% / 0% 0% 100% 100%;
}

.custom-border-radius-15 {
  border-radius: 0% 0% 15% 15% / 0% 0% 100% 100%;
}

.custom-border-radius-18 {
  border-radius: 0% 0% 18% 18% / 0% 0% 100% 100%;
}

.tab-transition {
  transition:
    height 0.6s ease,
    opacity 0.6s ease;
}

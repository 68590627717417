.rdrInRange {
  background: #304564;
}
.rdrStartEdge {
  background: #304564;
}
.rdrEndEdge {
  background: #304564;
}
.rdrCalendarWrapper {
  background: transparent;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  padding: 0;

  background-image: url('./assets/images/mapBg.png');
  background-repeat: repeat;
  background-size: cover;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media print {
  body {
    margin: 0; /* Reset default body margin */
    background: #fff;
  }

  .page-break {
    page-break-before: always;
    clear: both; /* Ensure content flows properly to the next page */
  }

  #liveCarReport {
    padding: 12px;
    margin-top: 12px;
  }

  #weeklyReport {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  @page {
    size: A3;
  }
}
